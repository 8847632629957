const filterGallery = {
	el: {
		containerPlaceholder: document.querySelector('.js-slider-placeholder'),
		containerThumbs: document.querySelector('.js-slider-thumbs'),
		categories: document.querySelectorAll('.js-filter-gallery')
	},

	vars: {
		placeholderItems: document.querySelector('.js-slider-placeholder') && document.querySelector('.js-slider-placeholder').querySelectorAll('.swiper-slide'),
		thumbsItems: document.querySelector('.js-slider-thumbs') && document.querySelector('.js-slider-thumbs').querySelectorAll('.swiper-slide')
	},

	fxToggleActiveCategory(element) {
		for (const item of this.el.categories)
			item.classList.remove('is-active')

		element.classList.add('is-active')
	},

	fxToggleSlides(slider, element) {

		const sliderItems = slider === this.el.containerPlaceholder ? this.vars.placeholderItems : this.vars.thumbsItems,
			swiper = slider.swiper,
			isOnlyOneSlide = slider.querySelectorAll('.filtered').length === 1,
			filteredItems = [...sliderItems].filter(slide => element.dataset.id === slide.dataset.id).map(slide => slide)

		this.el.containerPlaceholder.parentElement.querySelector('.slider__nav').style.display = isOnlyOneSlide ? 'none' : 'flex'

		swiper.prependSlide(sliderItems)

		for (const slide of sliderItems)
			slide.remove()

		//-put slides in order on prepend
		swiper.prependSlide(filteredItems.reverse())
		swiper.update()
		swiper.slideTo(0)
	},

	fxShowAllSlides(slider) {
		const sliderItems = slider === this.el.containerPlaceholder ? this.vars.placeholderItems : this.vars.thumbsItems,
			swiper = slider.swiper,
			isOnlyOneSlide = swiper.slides.length === 1

		this.el.containerPlaceholder.parentElement.querySelector('.slider__nav').style.display = isOnlyOneSlide ? 'none' : 'flex'

		for (const slide of sliderItems)
			slide.remove()

		swiper.appendSlide(sliderItems)

		swiper.update()
		swiper.slideTo(0)
	},

	fxFilterCategories(element) {
		const showCategories = () => {
			this.fxToggleSlides(this.el.containerPlaceholder, element)
			this.fxToggleSlides(this.el.containerThumbs, element)
		}

		const showAll = () => {
			this.fxShowAllSlides(this.el.containerPlaceholder)
			this.fxShowAllSlides(this.el.containerThumbs)
		}

		switch (true) {
			case element.classList.contains('js-filter-all'):
				showAll()
				break

			default:
				showCategories()
				break
		}
	},

	events() {
		for (const item of this.el.categories) {
			item.addEventListener('click', e => {
				this.fxToggleActiveCategory(e.currentTarget)
				this.fxFilterCategories(e.currentTarget)
			})
		}
	},

	init() {
		this.events()
		//NOTE: Added this because "tab "all" was removed from the filter
		this.el.categories[0] && window.addEventListener('load', () => {
			this.fxToggleActiveCategory(this.el.categories[0])
			this.fxFilterCategories(this.el.categories[0])
		})
	}
}
filterGallery.init()