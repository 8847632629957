/* global Swiper */
const sliderGrid = {
	el: {
		sliderContainer: document.querySelector('.js-slider-grid'),
		cardsSeeMore: document.querySelectorAll('.js-see-info'),
		articles: document.querySelectorAll('.slider-grid__article'),
		role: document.querySelector('.js-show-role'),
		name: document.querySelector('.js-show-name'),
		description: document.querySelector('.js-show-description'),
		sliderWrapper: document.querySelector('.swiper-wrapper')
	},

	vars: {
		extraLargeAndUp: window.matchMedia('(min-width: 75em)')
	},

	fxInitSlider(slider) {

		const isSliderCast = slider.classList.contains('js-slider-cast'),
			isSliderScrollbarMobile = slider.classList.contains('js-slider-scrollbar-mobile'),
			allSlides = slider.querySelectorAll('.swiper-slide')

		console.log(slider)

		if(window.innerWidth < 768 && allSlides.length < 3 && isSliderCast) {
			slider.parentElement.classList.add('swiper-off', 'is-mobile')
			return
		}

		if(window.innerWidth > 768 && allSlides.length < 4 && isSliderCast) {
			slider.parentElement.classList.add('swiper-off')
			return
		}

		const swiperOptions = {
			slidesPerView: 2,
			allowTouchMove: true,
			grabCursor: isSliderCast || isSliderScrollbarMobile ? true : false,
			// scrollbar: isSliderCast || isSliderScrollbarMobile ? {
			// 	el: '.swiper-scrollbar',
			// 	draggable: true,
			// 	hide: false
			// } : false,
			grid: {
				rows: 1,
				fill: 'row'
			},
			pagination: {
				el: slider.parentElement.querySelector('.swiper-pagination'),
				type: 'fraction'
			},
			navigation: {
				nextEl: slider.parentElement.querySelector('.swiper-button-next'),
				prevEl: slider.parentElement.querySelector('.swiper-button-prev')
			},
			breakpoints: {
				768: { // tablet up
					slidesPerView: 4,
					allowTouchMove: true,
					grid: {
						rows: 1,
						fill: 'row'
					}
				},
				1200: { // desktop up
					slidesPerView: 4,
					slidesPerGroup: isSliderCast ? 1 : 4,
					allowTouchMove: isSliderCast ? true : false,
					grid: {
						//-                      4
						rows: isSliderCast ? 1 : 5
					},
					scrollbar: isSliderCast ? {
						el: '.swiper-scrollbar',
						draggable: false,
						hide: false
					} : false,
					grabCursor: isSliderCast ? true : false
				}
			},
			on: {
				init: () => {
					setTimeout(() => {
						//-                                            16
						//-const isOnlyOneSlide = swiper.slides.length <= 20

						//-disable swiper on company page for desktop
						this.vars.extraLargeAndUp.matches && isSliderScrollbarMobile ? this.fxDisableSwiper(swiper) : ''

						//-slider.parentElement.querySelector('.slider__nav').style.display = this.vars.extraLargeAndUp.matches ? isOnlyOneSlide || isSliderCast ? 'none' : 'flex' : 'none'

						//removed swiper direction because of disabled swiper on company page
						//-this.fxChangeFlexDirection(swiper, isSliderCast)

						// console.log(slider.slides.length, slider.pagination.el.innerText)
						// const totalSlides = this.el.sliderContainer.querySelector('.swiper-pagination-total')
						// console.log(totalSlides)

					}, 500)
				},
				paginationRender: (slider, el) => {
					//-show total number of items/images instead of slides on slider-cast
					if(slider.el.classList.contains('js-slider-cast')) {
						const total = el.querySelector('.swiper-pagination-total')
						const newTotal = document.createElement('span')
						newTotal.textContent = slider.el.querySelectorAll('.swiper-slide:not(.swiper-slide-duplicate)').length

						total.remove()
						el.appendChild(newTotal)
					}
				}
				//-,
				//-resize: () => {
				//-                                            16
				//-const isOnlyOneSlide = swiper.slides.length <= 20

				//-slider.parentElement.querySelector('.slider__nav').style.display = this.vars.extraLargeAndUp.matches ? isOnlyOneSlide || isSliderCast ? 'none' : 'flex' : 'none'

				//removed swiper direction because of disabled swiper on company page
				//-this.fxChangeFlexDirection(swiper, isSliderCast)
				//-}
			},
			slideToClickedSlide: true,
			loop: isSliderCast ? true : false
		}

		const swiper = new Swiper(slider, swiperOptions)
	},

	fxChangeFlexDirection (swiper, isSliderCast) {
		this.el.sliderWrapper.style.flexDirection = !this.vars.extraLargeAndUp.matches ? 'row' : swiper.slides.length <= 20 ? 'row' : isSliderCast ? 'row' : 'column'
	},

	fxDisableSwiper(swiper) {
		swiper.disable()
	},
  
	fxShowToggleActive(element) {
		for (const card of this.el.cardsSeeMore)
			card.classList.remove('is-active')
		element.classList.add('is-active')
	},

	fxShowContent(element) {
		this.el.role.textContent = element.dataset.role
		this.el.name.textContent = element.dataset.name
		this.el.description.innerHTML = element.dataset.description
	},

	events() {
		for (const card of this.el.cardsSeeMore) {
			card.addEventListener('click', e => {
				this.fxShowToggleActive(e.currentTarget)
				this.fxShowContent(e.currentTarget)
			})
		}
	},

	init() {
		if (this.el.sliderContainer) {
			this.fxInitSlider(this.el.sliderContainer)
			this.events()
		}
	}
}
sliderGrid.init()