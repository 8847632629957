/*eslint-disable no-unused-vars*/
const preloader = {

	fxEnableButton(el) {
		el.classList.contains('js-preloader-file') ? el.style.pointerEvents = 'auto' : el.removeAttribute('disabled')
	},

	fxDisableButton(el) {
		el.classList.contains('js-preloader-file') ? el.style.pointerEvents = 'none' : el.setAttribute('disabled', true)
	},

	fxCreateSpinner(el) {
		const spinner = document.createElement('div')
		spinner.classList.add('btn--loading-spinner')
		el.appendChild(spinner)
	},

	fxRemoveSpinner(el) {
		el.querySelector('.btn--loading-spinner').remove()
	},

	fxPreloaderAdd(el) {
		const button = el.classList.contains('js-preloader-file') ? el : el.querySelector('.js-preloader')
		button.classList.add('btn--loading')
		this.fxDisableButton(button)
		this.fxCreateSpinner(button)
	},

	fxPreloaderRemove(el) {
		const button = el.classList.contains('js-preloader-file') ? el : el.querySelector('.js-preloader')
		button.classList.remove('btn--loading')
		this.fxEnableButton(button)
		this.fxRemoveSpinner(button)
	}
}