/*global intersectViewport*/
const filterNews = {
	el: {
		container: document.querySelector('.card-images__grid'),
		boxes: document.querySelectorAll('.card-images__box')
	},

	vars: {
		numberOfItemsLoaded: 9,
		numberOfItemsToShow: 9
	},

	fxLoadCardsOnInit() {
		const cardItems = [...this.el.container.children]

		for (const card of cardItems)
			card.classList.add('is-hidden')

		for (const card of cardItems.slice(0, this.vars.numberOfItemsLoaded))
			card.classList.remove('is-hidden')

	},

	fxLoadCardsOnScroll() {
		const callback = entries => {
			for (const entry of entries) {
				const el = entry.target
				if (entry.isIntersecting && !el.classList.contains('is-visible')) {
					setTimeout(() => {
						el.classList.remove('is-hidden')
						el.classList.add('is-visible')
					}, 500)

				}
			}
		}
		intersectViewport.fxCheckInViewport(this.el.boxes, callback, 1)
	},

	events() {
		window.addEventListener('load', () => window.scrollTo({ top: 0, behavior: 'smooth' }))
	},

	init() {
		if (this.el.container) {
			this.events()
			this.fxLoadCardsOnInit()
			this.fxLoadCardsOnScroll()
		}
	}
}
filterNews.init()