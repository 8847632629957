/* global Swiper */
const sliderModal = {
	el: {
		body: document.body,
		sliderGrid: document.querySelector('.js-slider-grid-controller'),
		sliderModal: document.querySelector('.js-slider-modal'),
		slides: document.querySelectorAll('.js-slide-active'),
		modalSlider: document.querySelector('.js-modal-slider'),
		btnCloseModal: document.querySelector('.js-close-modal-slider')
	},

	vars: {
		tabletAndUp: window.matchMedia('(max-width: 768px)')
	},

	fxOpenModalSlider () {
		this.el.body.classList.add('no-scroll-y')
		this.el.modalSlider.classList.add('open')
	},

	fxCloseModal () {
		this.el.body.classList.remove('no-scroll-y')
		this.el.modalSlider.classList.remove('open')
	},

	fxInitSlider (sliderGrid, sliderModal) {

		const _this = this,
			sliderGridSlides = sliderGrid.querySelectorAll('.swiper-slide')

		const swiperGridOptions = {
			slidesPerView: sliderGridSlides.length <= 2 ? sliderGridSlides.length : 2,
			allowTouchMove: true,
			grid: {
				rows: 1,
				fill: 'row'
			},
			pagination: {
				el: sliderGrid.parentElement.querySelector('.swiper-pagination'),
				type: 'fraction'
			},
			navigation: {
				nextEl: sliderGrid.parentElement.querySelector('.swiper-button-next'),
				prevEl: sliderGrid.parentElement.querySelector('.swiper-button-prev')
			},
			breakpoints: {
				768: { // tablet up
					slidesPerView: sliderGridSlides.length <= 4 ? sliderGridSlides.length : 4,
					allowTouchMove: true,
					grid: {
						rows: 1,
						fill: 'row'
					}
				}
			},
			on: {
				click (swiperGrid) {
					swiperModal.slideTo(swiperGrid.clickedIndex)
					_this.fxShowToggleActive(swiperGrid.clickedIndex)
					_this.fxOpenModalSlider()
				},
				paginationRender: (sliderGrid, el) => {
					//-show total number of items/images instead of slides on slider-cast
					if(sliderGrid.el.classList.contains('js-slider-grid-controller')) {
						const total = el.querySelector('.swiper-pagination-total')
						const newTotal = document.createElement('span')
						newTotal.textContent = sliderGrid.slides.length
	
						total.remove()
						el.appendChild(newTotal)
					}
				},

				init: () => setTimeout(() => window.dispatchEvent(new Event('resize')), 100),
				resize (sliderGrid) {
					const slidesLength = _this.vars.tabletAndUp.matches ? 2 : 4

					sliderGrid.slides.length <= slidesLength ? sliderGrid.el.nextElementSibling.style.display = 'none' : 'block'
				}
			}
		}

		const swiperGrid = new Swiper(sliderGrid, swiperGridOptions)

		const swiperModalOptions = {
			allowTouchMove: true,
			pagination: {
				el: sliderModal.parentElement.querySelector('.swiper-pagination'),
				type: 'fraction'
			},
			navigation: {
				nextEl: sliderModal.parentElement.querySelector('.swiper-button-next'),
				prevEl: sliderModal.parentElement.querySelector('.swiper-button-prev')
			},
			breakpoints: {
				768: { // tablet up
					allowTouchMove: true
				},
				1200: { // desktop up
					allowTouchMove: false,
					scrollbar:false,
					grabCursor: false
				}
			},
			on: {
				slideChange (swiperModal) {
					swiperGrid.slideTo(swiperModal.activeIndex)
					_this.fxShowToggleActive(swiperModal.activeIndex)
				}
			}
		}

		const swiperModal = new Swiper(sliderModal, swiperModalOptions)
	},

	fxShowToggleActive(index) {
		this.el.slides.forEach((el, i) => {
			el.classList.remove('is-active')
			i === index && el.classList.add('is-active')
		})
	},

	events () {
		this.el.btnCloseModal.addEventListener('click', () => {
			this.fxCloseModal()
		})
	},

	init () {
		if(!this.el.sliderModal) return
		this.events()
		this.fxInitSlider(this.el.sliderGrid, this.el.sliderModal)
	}
}

sliderModal.init()