/* global Swiper */
const sliderThumbs = {
	el: {
		sliderPlaceholder: document.querySelector('.js-slider-placeholder'),
		sliderThumbs: document.querySelector('.js-slider-thumbs')
	},

	fxInitSlider(sliderThumbs, sliderPlaceholder) {
		
		const swiperThumbsOptions = {
			spaceBetween: 10,
			slidesPerView: 5,
			freeMode: true,
			watchSlidesProgress: true,
			on: {
				init: () => setTimeout(() => window.dispatchEvent(new Event('resize')), 100)
			},
			breakpoints: {
				768: { // tablet up
					slidesPerView: 5
				},
				1200: { // desktop up
					slidesPerView: 8
				}
			}
		}

		const swiperThumbs = new Swiper(sliderThumbs, swiperThumbsOptions)

		const swiperPlaceholderOptions = {
			grabCursor: false,
			pagination: {
				el: sliderPlaceholder.querySelector('.swiper-pagination'),
				type: 'fraction'
			},
			navigation: {
				nextEl: sliderPlaceholder.querySelector('.swiper-button-next'),
				prevEl: sliderPlaceholder.querySelector('.swiper-button-prev')
			},
			thumbs: {
				swiper: swiperThumbs
			},
			on: {
				init: () => setTimeout(() => window.dispatchEvent(new Event('resize')), 100)
			}
		}

		const swiperPlaceholder = new Swiper(sliderPlaceholder, swiperPlaceholderOptions)

		if (swiperPlaceholder.slides.length <= 1) {
			sliderThumbs.style.display = 'none'
			sliderPlaceholder.parentElement.querySelector('.slider__nav').style.display = 'none'
		}
	},

	init() {
		this.el.sliderPlaceholder && this.fxInitSlider(this.el.sliderThumbs, this.el.sliderPlaceholder)
	}
}
sliderThumbs.init()
