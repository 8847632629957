/*eslint-disable no-unused-vars*/
const http = {
	async fxGet(endpoint, callback) {
		try {
			const response = await fetch(endpoint),
				data = await response.json()

			callback(response, data)
		}
		catch (error) {
			console.error(error)
		}
	},

	async fxPost(endpoint, formData, callback) {
		try {
			const response = await fetch(endpoint, {
				method: 'POST',
				body: JSON.stringify(formData),
				headers: {
					'Content-type': 'application/json'
				}
			})

			const data = await response.json()
			callback(response, data)
		}
		catch (error) {
			console.error(error)
		}
	},

	async fxUploadFile(endpoint, formData, callback) {
		try {
			const response = await fetch(endpoint, {
				method: 'POST',
				body: formData
			})

			const data = await response.json()
			callback(response, data)
		}
		catch (error) {
			console.error(error)
		}
	}
}