const windowHeight = {

	vars: {
		ticking: false
	},

	fxGetWindowHeight() {
		document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
	},

	fxThrottleResize() {
		if (!this.vars.ticking) {
			window.requestAnimationFrame(() => {
				this.fxGetWindowHeight()
				this.vars.ticking = false
			})

			this.vars.ticking = true
		}
	},

	events() {
		window.addEventListener('resize', () => this.fxThrottleResize())
	},

	init() {
		this.events()
		this.fxGetWindowHeight()
	}
}

windowHeight.init()