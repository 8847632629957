/* global video */
const modalVideo = {
	el: {
		btnOpenModal: document.querySelectorAll('.js-open-modal'),
		btnCloseModal: document.querySelector('.js-close-modal-video'),
		modalVideo:document.querySelector('.js-modal-video'),
		body: document.body
	},

	fxOpenModalVideo (e) {
		const type = e.dataset.type		

		//-mp4 video
		if(type === 'video') {
			const videoContainer = this.el.modalVideo.querySelector('.js-video'),
				videoPlayer = videoContainer.querySelector('.video__player'),
				videoSrc = videoContainer.querySelector('source')

			videoPlayer.poster = `${e.dataset.poster}`
			videoSrc.src = `${e.dataset.src}`

			videoPlayer.load()
			video.fxFetchVideo(videoPlayer)

		} else {
			//-youtube video
			const modalIframe = this.el.modalVideo.querySelector('.modal-video__iframe')

			modalIframe.src = `https://www.youtube.com/embed/${e.dataset.ytId}`
		}

		this.el.body.classList.add('no-scroll-y')
		this.el.modalVideo.classList.add('open')
		this.el.modalVideo.setAttribute('data-type', type)
	},

	fxCloseModalVideo () {
		const type = this.el.modalVideo.dataset.type
		if(type === 'video') {
			const videoContainer = this.el.modalVideo.querySelector('.js-video'),
				videoPlayer = videoContainer.querySelector('.video__player'),
				videoSrc = videoContainer.querySelector('source')

			!videoPlayer.paused && videoPlayer.parentElement.classList.remove('video-is-playing')
			videoPlayer.pause()
			videoPlayer.currentTime = 0
			videoPlayer.removeAttribute('poster')
			videoSrc.removeAttribute('src')
		} else {
			const modalIframe = this.el.modalVideo.querySelector('.modal-video__iframe')
			modalIframe.removeAttribute('src')
		}
		
		this.el.body.classList.remove('no-scroll-y')
		this.el.modalVideo.classList.remove('open')
	},

	events () {
		for(const btn of this.el.btnOpenModal) {
			btn.addEventListener('click', e => {
				this.fxOpenModalVideo(e.currentTarget)
			})
		}

		this.el.btnCloseModal.addEventListener('click', () => {
			this.fxCloseModalVideo()
		})
	},

	init () {
		if(!this.el.modalVideo) return
		this.events()
	}
}

modalVideo.init()