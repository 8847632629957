const sticky = {
	el: {
		header: document.querySelector('.header')
	},

	vars: {
		ticking: false
	},

	fxToggleColoredHeaderClass() {
		window.scrollY > 1 ? this.el.header.classList.add('header--colored') : this.el.header.classList.remove('header--colored')
	},

	fxThrottleScroll() {

		if (!this.vars.ticking) {
			window.requestAnimationFrame(() => {
				this.fxToggleColoredHeaderClass()
				this.vars.ticking = false
			})

			this.vars.ticking = true
		}
	},

	events() {
		window.addEventListener('scroll', () => this.fxThrottleScroll())
	},

	init() {
		this.events()
	}
}

sticky.init()