/*eslint-disable no-unused-vars*/
const intersectViewport = {

	fxCheckInViewport(element, callback, threshold = 0.5) {

		const observer = new IntersectionObserver(callback, { threshold: threshold })

		if (element instanceof Element) 
			observer.observe(element)
		
		else 
			for (const el of element)
				observer.observe(el)
	}
}