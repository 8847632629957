/* global Swiper */
const sliderHero = {
	el: {
		sliderContainer: document.querySelector('.js-slider-hero')
	},

	fxInitSlider(slider) {
		const autoplay = JSON.parse(slider.dataset.sliderAutoplay),
			delay = JSON.parse(slider.dataset.sliderDelay),
			loop =  JSON.parse(slider.dataset.sliderLoop)

		const swiperOptions = {
			autoplay: autoplay ? { delay: delay } : false,
			loop: loop && slider.querySelectorAll('.swiper-slide').length > 1 ? true : false,
			pagination: {
				clickable: true,
				el: slider.querySelector('.swiper-pagination')
			},
			on: {
				init: () => setTimeout(() => window.dispatchEvent(new Event('resize')), 100)
			}
		}

		new Swiper(slider, swiperOptions)
	},

	init() {
		this.el.sliderContainer && this.fxInitSlider(this.el.sliderContainer)
	}
}
sliderHero.init()