const introVideo = {
	el: {
		introContainer: document.querySelector('.js-intro-video'),
		btnCloseIntro: document.querySelector('.js-close-intro-video'),
		body: document.body
	},

	fxCloseIntro () {
		const videoContainer = this.el.introContainer.querySelector('.js-video'),
			videoPlayer = videoContainer.querySelector('.video__player')

		!videoPlayer.paused && videoPlayer.parentElement.classList.remove('video-is-playing')
		document.fullscreenElement && document.exitFullscreen()
		videoPlayer.pause()
		videoPlayer.currentTime = 0
		
		this.el.body.classList.remove('no-scroll-y')
		this.el.introContainer.classList.add('close')

		this.fxSetIntroVideoHasPlayedLocalstorage()
	},

	fxCheckIfIntroVideoPlayed () {
		if(localStorage.getItem('videoHasShown') !== null) {
			this.el.body.classList.remove('no-scroll-y')
			this.el.introContainer.classList.add('close')
		}	
	},

	fxSetIntroVideoHasPlayedLocalstorage () {
		localStorage.setItem('videoHasShown', true)
	},

	events () {
		this.el.btnCloseIntro.addEventListener('click', () => {
			this.fxCloseIntro()
		})

		const videoPlayer = this.el.introContainer.querySelector('.video__player')
		videoPlayer.addEventListener('ended', () => {
			this.fxCloseIntro()
		})

		videoPlayer.addEventListener('play', () => {
			this.fxSetIntroVideoHasPlayedLocalstorage()
		})

		this.fxCheckIfIntroVideoPlayed()
	},

	init () {
		if(!this.el.introContainer) return
		this.events()
	}
}

introVideo.init()
