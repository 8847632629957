const nav = {
	el: {
		body: document.body,
		header: document.querySelector('.header'),
		hamburger: document.querySelector('.js-hamburger'),
		hamburgerCopy: document.querySelector('.js-hamburger-copy'),
		navContainer: document.querySelector('.nav')
	},

	fxToggleNav(element) {
		const menuCopy = this.el.hamburgerCopy

		setTimeout(() => element.classList.toggle('header__button--active'), 600)
		this.el.body.classList.toggle('no-scroll-hidden')
		this.el.navContainer.classList.toggle('nav--open')
		menuCopy.textContent = this.el.navContainer.classList.contains('nav--open') ? menuCopy.dataset.menuClose : menuCopy.dataset.menuOpen
		this.el.navContainer.classList.contains('nav--open') ? this.el.header.classList.add('header--colored', 'header--open') : this.el.header.classList.remove('header--open') ? window.scrollY < 1 && this.el.header.classList.remove('header--colored') : ''
	},

	events() {
		this.el.hamburger.addEventListener('click', e => this.fxToggleNav(e.currentTarget))
	},

	init() {
		this.events()
	}
}

nav.init()