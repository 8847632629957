const video = {
	el: {
		videoContainer: document.querySelectorAll('.js-video')
	},

	fxTabSwitch(videoPlayer) {
		let inTab = true

		document.addEventListener('visibilitychange', () => {
			inTab = !inTab
			inTab && videoPlayer.parentElement.classList.contains('video-is-playing') ? videoPlayer.play() : videoPlayer.pause()
		})
	},

	fxTimeUpdateVideo(videoPlayer) {
		if (videoPlayer.parentElement.classList.contains('video-is-playing')) {

			videoPlayer.addEventListener('timeupdate', e => {
				const target = e.currentTarget,
					percentage = Math.round(target.currentTime / target.duration * 100)

				if (target.currentTime || target.duration) {
					target.parentElement.querySelector('.js-video-seekbar').value = percentage
				}
			})
		}
	},

	fxFetchVideo (video) {
		fetch(video.querySelector('source').src)
			.then(response => {
				response.blob()
			})
			.then(blob => {
				video.srcObject = blob
				return video.pause()
			})
			.catch(error => {
				console.log(error)
			})
	},

	fxTogglePlayVideo(elementsToPlay, videoPlayer) {
		for (const buttonPlay of elementsToPlay) {
			buttonPlay.addEventListener('click', () => {
				videoPlayer.parentElement.classList.toggle('video-is-playing')
				videoPlayer.parentElement.parentElement.classList.contains('video-jumbotron') && videoPlayer.parentElement.parentElement.classList.toggle('video-jumbotron--no-height')

				if (videoPlayer.parentElement.classList.contains('video-is-fullscreen')) return
				videoPlayer.paused ? videoPlayer.play() : videoPlayer.pause()

				this.fxTimeUpdateVideo(videoPlayer)
			})
		}
	},

	fxToggleSoundVideo(soundButton, videoPlayer) {
		soundButton.addEventListener('click', e => {
			e.currentTarget.classList.toggle('video-has-sound')
			videoPlayer.muted = !videoPlayer.muted
		})
	},

	fxProgressVideo(progressBar, videoPlayer) {
		progressBar.addEventListener('click', e => {
			const percentage = (e.pageX - e.currentTarget.getBoundingClientRect().left) / e.currentTarget.clientWidth,
				time = videoPlayer.duration * percentage

			videoPlayer.currentTime = time
		})
	},

	fxActionsOnEndVideo(videoPlayer) {
		videoPlayer.addEventListener('ended', () => {
			videoPlayer.parentElement.classList.remove('video-is-playing')
			videoPlayer.currentTime = 0
			videoPlayer.load()
		})
	},

	fxSetFullScreenVideo(button, videoPlayer) {
		if(!videoPlayer.requestFullscreen || !videoPlayer.webkitRequestFullscreen) 
			button.style.display = 'none'
			
		button.addEventListener('click', () => videoPlayer.requestFullscreen && videoPlayer.requestFullscreen() || videoPlayer.webkitRequestFullscreen && videoPlayer.webkitRequestFullscreen())
		videoPlayer.addEventListener('fullscreenchange', e => e.currentTarget.parentElement.classList.toggle('video-is-fullscreen'))
	},

	events() {
		for (const videoContainer of this.el.videoContainer) {
			const isModal = videoContainer.parentElement.classList.contains('modal-video__container') && true,
				playButton = videoContainer.querySelectorAll('.js-video-play'),
				soundButton = videoContainer.querySelector('.js-video-sound'),
				fullScreenButton = videoContainer.querySelector('.js-video-fullscreen'),
				progressBar = videoContainer.querySelector('.js-video-seekbar'),
				videoPlayer = videoContainer.querySelector('video')

			if(!isModal) {
				videoPlayer.load()
				this.fxFetchVideo(videoPlayer)
			}
		
			this.fxTogglePlayVideo(playButton, videoPlayer)
			this.fxToggleSoundVideo(soundButton, videoPlayer)
			this.fxSetFullScreenVideo(fullScreenButton, videoPlayer)
			this.fxProgressVideo(progressBar, videoPlayer)
			this.fxTabSwitch(videoPlayer)
			this.fxActionsOnEndVideo(videoPlayer)
		}
	},

	init() {
		this.events()
	}
}

video.init()