/* global Swiper */
const sliderCardRows = {
	el: {
		sectionContainer: document.querySelector('.card-rows'),
		sliderContainer: document.querySelectorAll('.js-slider-card-rows')
	},

	fxInitSlider(slider) {
		const swiperOptions = {
			slidesPerView: 1.5,
			centeredSlides: true,
			loop: true,
			allowTouchMove: false,
			speed: 6000,
			autoplay: {
				delay: 1,
				reverseDirection: slider.parentElement.classList.contains('card-rows__row--bottom') && true
			},
			breakpoints: {
				768: { //tablet up
					slidesPerView: 2.5
				},
				1200: { // desktop up
					slidesPerView: 4
				}
			},
			on: {
				init: () => setTimeout(() => window.dispatchEvent(new Event('resize')), 100)
			}
		}

		new Swiper(slider, swiperOptions)
	},

	init() {
		for (const slider of this.el.sliderContainer)
			this.fxInitSlider(slider)
	}
}
sliderCardRows.init()