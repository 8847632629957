const newsHighlights = {
	el: {
		articles: document.querySelectorAll('.js-article'),
		placeholder: document.querySelector('.js-placeholder')
	},
	
	fxShowImageArticle(element) {
		const currentImage = element.querySelector('img').src
		this.el.placeholder.src = currentImage
		this.el.placeholder.style.transform = 'rotate(8deg)'
	},

	fxRemoveImageStyle() {
		this.el.placeholder.removeAttribute('style')
	},

	events() {
		for (const article of this.el.articles) {
			article.addEventListener('mouseenter', e => this.fxShowImageArticle(e.currentTarget))
			article.addEventListener('mouseleave', () => this.fxRemoveImageStyle())
		}
	},

	init() {
		this.el.placeholder && this.events()
	}
}

newsHighlights.init()