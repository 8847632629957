const removeSessionStorage = {
	resetSessionStorage () {
		const path = window.location.pathname,
			conditions = ['productions', 'producoes']
		
		const checkPathname = conditions.some(el => path.includes(el))
		
		if(!checkPathname) {
			sessionStorage.clear()
		}
	},

	init () {
		this.resetSessionStorage()
	}
}

removeSessionStorage.init()