/*global intersectViewport*/
const filterProductions = {
	el: {
		container: document.querySelector('.card-images__grid-gapless'),
		boxes: document.querySelectorAll('.card-images__box-gapless'),
		categories: document.querySelectorAll('.js-filter-productions')
	},

	vars: {
		numberOfItemsLoaded: 12,
		numberOfItemsToShow: 12
	},

	fxToggleActiveCategory(element) {
		for (const item of this.el.categories)
			item.classList.remove('is-active')

		element.classList.add('is-active')
	},

	fxLoadCardsOnInit() {
		const cardItems = [...this.el.container.children]

		for (const card of cardItems)
			card.classList.add('is-hidden')

		for (const card of cardItems.slice(0, this.vars.numberOfItemsLoaded))
			card.classList.remove('is-hidden')

	},

	fxLoadCardsOnScroll() {
		const callback = entries => {
			for (const entry of entries) {
				const el = entry.target
				if (entry.isIntersecting && !el.classList.contains('is-visible')) {
					setTimeout(() => {
						el.classList.remove('is-hidden')
						el.classList.add('is-visible')
					}, 500)

				}
			}
		}
		intersectViewport.fxCheckInViewport(this.el.boxes, callback, 1)
	},

	fxFilterCategories(element) {
		const cardItems = [...this.el.container.children]
		this.vars.numberOfItemsToShow = this.vars.numberOfItemsToShow + this.vars.numberOfItemsLoaded

		const showCategories = () => {
			for (const card of cardItems) {
				card.classList.remove('is-hidden', 'filtered', 'is-visible')
				const conditionFilter = element.classList.contains('js-filter-prize') ? card.dataset.prize === 'true' : Number(element.dataset.id) === Number(card.dataset.id)
				conditionFilter ? card.classList.add('filtered') : card.classList.add('d-none')
			}

			const cardsFiltered = [...this.el.container.querySelectorAll('.filtered')]

			for (const card of cardsFiltered)
				card.classList.add('d-none', 'is-hidden')

			for (const card of cardsFiltered.slice(0, this.vars.numberOfItemsLoaded))
				card.classList.remove('is-hidden')

			for (const card of cardsFiltered.slice(0, this.vars.numberOfItemsToShow))
				card.classList.remove('d-none')

		}

		const showAll = () => {
			for (const card of cardItems) {
				card.classList.remove('filtered', 'd-none', 'is-visible')
				card.classList.add('is-hidden')
			}

			for (const card of cardItems.slice(0, this.vars.numberOfItemsLoaded))
				card.classList.remove('is-hidden')

		}

		switch (true) {
			case element.classList.contains('js-filter-all'):
				showAll()
				break

			default:
				showCategories()
				break
		}
	},

	fxUpdateFilterCategories() {
		//-get tab value on session
		let tabSelected = sessionStorage.getItem('tabFilterProductions') || undefined

		if(tabSelected === undefined) {
			this.fxToggleActiveCategory(this.el.categories[0])
			this.fxFilterCategories(this.el.categories[0])
		} else {
			for (const item of this.el.categories) {
				if(item.textContent === tabSelected) {
					this.fxToggleActiveCategory(item)
					this.fxFilterCategories(item)
				}
			}
		}
	},

	events() {
		for (const item of this.el.categories) {
			item.addEventListener('click', e => {
				this.fxToggleActiveCategory(e.currentTarget)
				this.fxFilterCategories(e.currentTarget)
				sessionStorage.setItem('tabFilterProductions', e.currentTarget.textContent)
			})
		}

		const callback = entries => {
			for (const entry of entries) {
				const el = entry.target
				if (entry.isIntersecting && !el.classList.contains('is-visible')) {
					setTimeout(() => {
						el.classList.remove('is-hidden')
						el.classList.add('is-visible')
					}, 500)

				}
			}
		}
		intersectViewport.fxCheckInViewport(this.el.boxes, callback, 1)

		window.addEventListener('load', () => window.scrollTo({ top: 0, behavior: 'smooth' }))
	},

	init() {
		if (this.el.container && !this.el.container.classList.contains('card-images__grid-gapless--no-filter')) {
			this.events()
			this.fxLoadCardsOnInit()
			this.fxLoadCardsOnScroll()
			this.fxUpdateFilterCategories()
		}
	}
}
filterProductions.init()