/*global intersectViewport*/
const numbers = {
	el: {
		numberElements: document.querySelectorAll('.js-number')
	},

	vars: {
		duration: 1000,
		delay: 16
	},

	fxAnimateNumbers(numbers) {
		const counterUp = window.counterUp.default

		const callback = entries => {

			for (const entry of entries) {
				const el = entry.target
				if (entry.isIntersecting && !el.classList.contains('is-visible')) {
					counterUp(el, {
						duration: this.vars.duration,
						delay: this.vars.delay
					})
					el.classList.add('is-visible')
				}
			}
		}

		intersectViewport.fxCheckInViewport(numbers, callback)

	},

	init() {
		this.fxAnimateNumbers(this.el.numberElements)
	}
}

numbers.init()